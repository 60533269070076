.burnout-range-wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.burnout-range {
  -webkit-appearance: none !important;
  width: 100% !important;
  height: 8px !important;
  background: linear-gradient(
    45deg,
    /* Diagonal direction */ #e63946 0%,
    #e63946 20%,
    /* 20% Red */ #f77f00 20%,
    #f77f00 40%,
    /* 20% Orange */ #fcbf49 40%,
    #fcbf49 60%,
    /* 20% Yellow */ #aeda07 60%,
    #aeda07 80%,
    /* 20% Light Blue */ #4caf50 80%,
    #4caf50 100% /* 20% Green */
  ) !important;
  border-radius: 10px !important;
  outline: none !important;
  padding: 0 !important;
  margin: 0 !important;
  transition: background 0.3s ease-in-out !important;

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    appearance: none !important;
    width: 4px !important; /* Smaller width */
    height: 16px !important; /* Smaller height */
    background: #324d72 !important;
    cursor: pointer !important;
    transition: transform 0.2s ease-in-out !important; /* Smooth dragging effect */
  }

  &::-moz-range-thumb {
    width: 4px !important;
    height: 16px !important;
    background: #324d72 !important;
    cursor: pointer !important;
    transition: transform 0.2s ease-in-out !important;
  }

  &::-ms-thumb {
    width: 4px !important;
    height: 16px !important;
    background: #324d72 !important;
    cursor: pointer !important;
    transition: transform 0.2s ease-in-out !important;
  }
}
