.chat-bubble {
    max-width: 80%;
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
  }
  
  .user {
    background-color: #007bff;
    color: #fff;
    align-self: flex-end;
  }
  
  .ai {
    background-color: #f0f0f0;
    color: #333;
    align-self: flex-start;
  }
  