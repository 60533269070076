/* Normal range input styles */
input.range-input[type="range"] {
  height: 27px;
  background: inherit;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input.range-input[type="range"]:focus {
  outline: none;
}
input.range-input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: #000;
  border-radius: 15px;
}
input.range-input[type="range"]::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 20px;
  width: 38px;
  border-radius: 12px;
  background: #f48d79;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}
input.range-input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000;
}
input.range-input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #000;
  border-radius: 15px;
}
input.range-input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #000000;
  height: 20px;
  width: 38px;
  border-radius: 12px;
  background: #f48d79;
  cursor: pointer;
}
input.range-input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
}
input.range-input[type="range"]::-ms-fill-lower {
  background: #000;
  border-radius: 30px;
}
input.range-input[type="range"]::-ms-fill-upper {
  background: #000;
  border-radius: 30px;
}
input.range-input[type="range"]::-ms-thumb {
  margin-top: 1px;
  height: 20px;
  width: 38px;
  border-radius: 12px;
  background: #f48d79;
  cursor: pointer;
}
input.range-input[type="range"]:focus::-ms-fill-lower {
  background: #000;
}
input.range-input[type="range"]:focus::-ms-fill-upper {
  background: #000;
}

/* Additional style for disabled range input */
input.range-input[type="range"]:disabled::-webkit-slider-runnable-track {
  background: #ccc; /* Gray color when disabled */
}
input.range-input[type="range"]:disabled::-moz-range-track {
  background: #ccc; /* Gray color when disabled */
}

/* Add opacity and change color for disabled range thumb */
input.range-input[type="range"]:disabled::-webkit-slider-thumb {
  opacity: 0.9; /* Opacity when disabled */
  background: #f48d79; /* Color when disabled */
}
input.range-input[type="range"]:disabled::-moz-range-thumb {
  opacity: 0.9; /* Opacity when disabled */
  background: #f48d79; /* Color when disabled */
}
