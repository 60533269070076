@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  // background-color: rgb(245, 246, 246);
  font-family: "GIP-Regular";
}

.unity-canvas {
  // position: absolute;
  top: 0px;
  /* left: 100px; */
  width: 1152px;
  height: 576px;
  z-index: 1;
}

@media screen and (max-width: 1268px) {
  /* Styles for tablets and smaller devices */
  .unity-canvas {
    // position: absolute;
    top: 0px;
    /* left: 100px; */
    width: 652px;
    height: 276px;
    z-index: 1;
  }
}

.unity-canvas-test-mobile-container {
  // position: fixed;
  /* Positioned relative to the viewport */
  top: 0;
  left: 0;
  width: 100vw;
  /* Full viewport width */
  height: 100vh;
  /* Full viewport height */
  transform-origin: center;

  .unity-canvas-test1 {
    transform: rotate(-90deg);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Full width of the container */
    height: 100%;
    /* Full height of the container */
    z-index: 1;
  }
}

#unity-container {
  // width: 100%; /* Set initial width */
  // height: 100%; /* Set initial height */
  overflow: hidden;

  /* Centering the container might be necessary */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Initial transform setup */
  transform-origin: center;
}

.unity-canvas-mobile {
  // position: absolute;
  top: 0px;
  /* left: 100px; */
  width: 450px;
  height: 180px;
  z-index: 1;
}

.apexcharts-toolbar {
  display: none !important;
}

.apexcharts-legend {
  justify-content: left !important;
}

.apexcharts-legend-series {
  justify-content: left !important;
}

.apexcharts-legend-text {
  text-align: left !important;
}

.apexcharts-legend-series {
  margin-top: 10px !important; // adjust this value as needed
}

//   .apexcharts-legend {
//     width: 200px !important;
//   }

.haha {
  font-size: 400px;
}

.MuiSlider-thumb {
  height: 30px !important;
  width: 30px !important;
  color: #ffb596 !important;
}

.MuiSlider-sizeSmall {
  color: black !important;
}

.chartPolaris {
  cursor: pointer !important;
}

// Import base styles
@import "~flatpickr/dist/flatpickr.min.css";

// Customise flatpickr
$calendarPadding: 24px;
$daySize: 36px;
$daysWidth: $daySize * 7;

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -8px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.flatpickr-calendar {
  border: inherit;
  @apply rounded shadow-lg border border-slate-200 left-1/2;
  margin-left: -($daysWidth + $calendarPadding * 2) * 0.5;
  padding: $calendarPadding;
  width: $daysWidth + $calendarPadding * 2;
}

@screen lg {
  .flatpickr-calendar {
    @apply left-auto right-0;
    margin-left: 0;
  }
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 200ms ease-out;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 4px);
}

.flatpickr-calendar.static.open {
  z-index: 20;
}

.flatpickr-days {
  width: $daysWidth;
}

.dayContainer {
  width: $daysWidth;
  min-width: $daysWidth;
  max-width: $daysWidth;
}

.flatpickr-day {
  @apply bg-slate-50 text-sm font-medium text-slate-600;
  max-width: $daySize;
  height: $daySize;
  line-height: $daySize;
}

.flatpickr-day,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay {
  border: none;
}

.flatpickr-day,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 0;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  @apply text-slate-400;
}

.rangeMode .flatpickr-day {
  margin: 0;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @apply bg-indigo-500 text-indigo-50;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  @apply bg-indigo-400 text-indigo-50;
}

.flatpickr-day.inRange,
.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n + 1)) {
  box-shadow: none;
}

.flatpickr-months {
  align-items: center;
  margin-top: -8px;
  margin-bottom: 6px;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  position: static;
  height: auto;
  @apply text-slate-600;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 7px;
  height: 11px;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover,
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: inherit;
  @apply text-slate-400;
}

.flatpickr-months .flatpickr-prev-month {
  margin-left: -10px;
}

.flatpickr-months .flatpickr-next-month {
  margin-right: -10px;
}

.flatpickr-months .flatpickr-month {
  @apply text-slate-800;
  height: auto;
  line-height: inherit;
}

.flatpickr-current-month {
  @apply text-sm font-medium;
  position: static;
  height: auto;
  width: auto;
  left: auto;
  padding: 0;
}

.flatpickr-current-month span.cur-month {
  @apply font-medium m-0;
}

.flatpickr-current-month span.cur-month:hover {
  background: none;
}

.flatpickr-current-month input.cur-year {
  font-weight: inherit;
  box-shadow: none !important;
}

.numInputWrapper:hover {
  background: none;
}

.numInputWrapper span {
  display: none;
}

span.flatpickr-weekday {
  @apply text-slate-400 font-medium text-xs;
}

.flatpickr-calendar.arrowTop::before,
.flatpickr-calendar.arrowTop::after {
  display: none;
}

// Range slider
$range-thumb-size: 36px;

input[type="range"] {
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  height: 6px;
  margin-top: ($range-thumb-size - 6px) * 0.5;
  margin-bottom: ($range-thumb-size - 6px) * 0.5;
  --thumb-size: #{$range-thumb-size};

  &::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: $range-thumb-size;
    width: $range-thumb-size;
  }

  &::-moz-range-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: $range-thumb-size;
    width: $range-thumb-size;
  }

  &::-ms-thumb {
    background-color: #000;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: $range-thumb-size;
    width: $range-thumb-size;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  &:focus {
    @apply ring-0;
  }
}

// Switch element
.form-switch {
  @apply relative select-none;
  width: 44px;

  label {
    @apply block overflow-hidden cursor-pointer h-6 rounded-full;

    >span:first-child {
      @apply absolute block rounded-full;
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px;
      right: 50%;
      transition: all 0.15s ease-out;
    }
  }

  input[type="checkbox"] {
    &:checked {
      +label {
        @apply bg-indigo-500;

        >span:first-child {
          left: 22px;
        }
      }
    }
  }
}

// Typography
.h1 {
  @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
  @apply text-3xl font-extrabold;
}

.h4 {
  @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

// Buttons
.btn,
.btn-sm,
.btn-xs {
  @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
  @apply px-2.5 py-2;
}

.btn-sm {
  @apply px-2 py-1;
}

.btn-xs {
  @apply px-2 py-0.5;
}

// Forms
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply text-sm text-slate-800 bg-white border;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

// .form-input,
// .form-textarea,
// .form-multiselect,
// .form-select {
//     // @apply leading-5 py-2 px-3 border-slate-200 hover: border-slate-300 focus:border-indigo-300 shadow-sm;
// }

.form-input,
.form-textarea {
  @apply placeholder-slate-400;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-indigo-500 border border-slate-300;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@font-face {
  font-family: "GIP-Regular";
  src: url("./fonts/GIP-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GIP-Bold";
  src: url("./fonts/GIP-ExtraBoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GIP-Italic";
  src: url("./fonts/GIP-RegularItalic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

.ag-root {
  font-family: "GIP-Regular";
}

.languageSelection {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.requirementSelection {
  .MuiInputBase-formControl {
    background-color: white;
    border-radius: 10px;
  }
}

.ag-gred-row .ag-cell {
  display: flex;
  align-items: center;
  // justify-content: center;
}

.ag-theme-balham {
  --ag-odd-row-background-color: #e04f00;
}

.ag-theme-balham .ag-row-odd {
  background-color: var(--ag-odd-row-background-color);
}

.ag-header {
  border: none !important;
  background-color: white !important;
}

.ag-root-wrapper {
  border: none !important;
}

.ql-toolbar {
  border-radius: 10px 10px 0 0 !important;
}

.ql-container {
  border-radius: 0 0 10px 10px !important;
}

.formAutoComplete {
  .MuiAutocomplete-input {
    height: 11px;
  }

  .MuiAutocomplete-inputRoot {
    border-radius: 10px;
  }
}

.formAutoComplete48 {
  .MuiAutocomplete-input {
    height: 15px;
  }

  .MuiAutocomplete-inputRoot {
    border-radius: 10px;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.animate-slide-in-left {
  animation: slideInLeft 0.3s forwards;
}

.animate-slide-out-left {
  animation: slideOutLeft 0.3s forwards;
}

.bottomSpace.has-margin {
  margin-bottom: 20px;
}