.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Set the chat container to occupy the full viewport height */
  }
  
  .chat-messages {
    flex-grow: 1;
    overflow-y: auto; /* Enable vertical scrolling for chat messages */
  }
  
  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: sticky;
    bottom: 0; /* Stick the input container to the bottom */
    background-color: #f0f0f0; /* Give it a background color for contrast */
  }
  
  .input-container input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc; 
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .input-container button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .input-container button:hover {
    background-color: #0056b3;
  }
  