@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/flatpickr.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;
:root {
  --colour1: #f1c40f;
  --colour2: #2c3e50;
  --speed: 4s;
}

@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 113px;
  }
}

/* Other styles if needed */
.spin-animation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// ona table switch

/* Table.css */
@keyframes switch {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-switch {
  animation: switch 0.3s ease-in-out;
}

.scrolling {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
}
