.custom-checkbox {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.custom-checkbox input {
  opacity: 0;
  position: absolute;
}

.checkmark {
  width: 20px;
  height: 20px;
  border: 2px solid #cecfd3;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  transition: border-color 0.2s ease;
}

.checkmark::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: #00d97d;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

.custom-checkbox input:checked + .checkmark {
  border-color: #00d97d;
}

.custom-checkbox input:checked + .checkmark::before {
  transform: translate(-50%, -50%) scale(1);
}

.rounded-full {
  border-radius: 50%;
}

.rounded-10px {
  border-radius: 10px;
}
